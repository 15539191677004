/* component imports */
import AuthForm from './AuthForm';
import Card from '../UI/Card';

/* styles import */
import styles from './Auth.module.css'

const Auth = () => {
    return <Card className={styles.auth}>
        <AuthForm />
    </Card>
}

export default Auth;