/* main imports */
import { useContext } from "react";

/* components import  */
import ProductsList from "./ProductsList";
import ProductsContext from "../../store/products-context";

/* styles import  */
import styles from "./Products.module.css";

const Products = () => {
  const productCtx = useContext(ProductsContext);

  const favoriteToggleHandler = id => {
    productCtx.favoriteToggle(id)
  }

  let productsOutput = <p>Found No Products!</p>;

  if (productCtx.items.length > 0) {
    productsOutput = productCtx.items.map((product) => (
      <ProductsList
        key={product.id}
        id={product.id}
        title={product.title}
        description={product.description}
        favorite={product.isFavorite}
        onFavoriteToggle={favoriteToggleHandler}
      />
    ));
  }

  return <section className={styles.products}>{productsOutput}</section>;
};

export default Products;
