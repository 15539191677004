import React, { useState } from "react";

const ProductsContext = React.createContext({
  items: [],
  favoriteToggle: (id) => {},
});

const DUMMY_PRODUCTS = [
  {
    id: "p1",
    title: "Red Scarf",
    description: "A pretty red scarf",
    isFavorite: false,
  },
  {
    id: "p2",
    title: "Green Scarf",
    description: "A pretty green scarf",
    isFavorite: false,
  },
  {
    id: "p3",
    title: "Halit Mehmood",
    description: "Andrews Meyer Porter",
    isFavorite: false,
  },
  {
    id: "p4",
    title: "King Hamilton",
    description: "Hudson Morrison Chavez",
    isFavorite: false,
  },
  {
    id: "p5",
    title: "Flores Sullivan",
    description: "Gonzales Nguyen Medina",
    isFavorite: false,
  },
  {
    id: "p6",
    title: "Cooper Kim",
    description: " Walsh O'Brien Russell",
    isFavorite: false,
  },
  {
    id: "p7",
    title: "Sullivan Elliott",
    description: "Jordan Hunter Lopez",
    isFavorite: false,
  },
];

export const ProductProvider = (props) => {
  const [productsItem, setProductsItem] = useState(DUMMY_PRODUCTS);

  const favoriteToggleHandler = (id) => {
    const itemIndex = productsItem.findIndex((favItem) => favItem.id === id);
    const item = productsItem[itemIndex];

    const updatedItem = {
      ...item,
      isFavorite: !item.isFavorite,
    };

    const updatedProductItem = productsItem.filter((item) => item.id !== id);

    setProductsItem((prevState) => {
      return [updatedItem, ...updatedProductItem];
    });
  };

  const productsValue = {
    items: productsItem,
    favoriteToggle: favoriteToggleHandler,
  };

  return (
    <ProductsContext.Provider value={productsValue}>
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsContext;
