import React, { useState } from "react";

const FavoriteContext = React.createContext({
  items: [],
  addItemToFavorite: (item) => {},
  removeItemFromFavorite: (id) => {},
});

export const FavoriteProvider = (props) => {
  const [favoriteItems, setFavoriteItems] = useState([]);

  const addItemHandler = (item) => {
    setFavoriteItems(prevState => {
      return [item,...prevState]
    })

  };

  const removeItemHandler = (id) => {
    const updateFavoriteItems = favoriteItems.filter(item => item.id !== id)

    setFavoriteItems(updateFavoriteItems)
  };

  const favoriteValue = {
    items: favoriteItems,
    addItemToFavorite: addItemHandler,
    removeItemFromFavorite: removeItemHandler,
  };

  return (
    <FavoriteContext.Provider value={favoriteValue}>
      {props.children}
    </FavoriteContext.Provider>
  );
};

export default FavoriteContext;
