/* main imports */
import { useContext } from "react";

/* component imports  */
import Card from "../UI/Card";
import ProductsItem from "./ProductsItem";
import FavoriteContext from "../../store/favorite-context";

/* styles import */
import styles from "./ProductsList.module.css";

const ProductsList = (props) => {
  const favoriteCtx = useContext(FavoriteContext);

  const favoriteItemClick = (event) => {
    // if (event.target.value === "favorite") {
    //   const favoriteItem = {
    //     id: props.id,
    //     title: props.title,
    //     description: props.description,
    //   };
    //   favoriteCtx.addItemToFavorite(favoriteItem);
    //   props.onFavoriteToggle(props.id)
    // } else {
    //   props.favoriteToggle(props.id)
    //   props.onFavoriteToggle(props.id)
    // }

    if (props.favorite) {
      props.onFavoriteToggle(props.id)
      favoriteCtx.removeItemFromFavorite(props.id)
    } else {
      const favoriteItem = {
        id: props.id,
        title: props.title,
        description: props.description,
      };

      favoriteCtx.addItemToFavorite(favoriteItem);
      props.onFavoriteToggle(props.id)
    }
  };

  return (
    <Card className={styles["product-list"]}>
      <ProductsItem
        id={props.id}
        title={props.title}
        description={props.description}
      />
      <div className={styles.actions}>
        <button onClick={favoriteItemClick} className={props.favorite ? styles.active : '' }>
          {!props.favorite ? "Favorite" : "Un-Favorite"}
        </button>
      </div>
    </Card>
  );
};

export default ProductsList;
