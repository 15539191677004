import notFoundImage from "../assets/not-found.svg";

const NotFound = () => {
  return (
    <img
      src={notFoundImage}
      style={{
        width: "500px",
        display: "block",
        margin: "0 auto",
        marginTop: "45px",
      }}
      alt="not found"
    />
  );
};

export default NotFound;
