/* main import */
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import FavoriteContext from "../../store/favorite-context";

/* compontent imports */
import FavoriteList from "./FavoriteList";

/* styles imoprt */
import styles from "./Favorite.module.css";

const ProductsFavorite = (props) => {
  const favoriteCtx = useContext(FavoriteContext);

  let favoriteOutput = (
    <Fragment>
      <p className={styles["not-found"]}>You Have No Favorite Yet</p>
      <div className={styles.actions}>
        <button>
          <Link to="/">Choose One</Link>
        </button>
      </div>
    </Fragment>
  );

  if (favoriteCtx.items.length > 0) {
    favoriteOutput = favoriteCtx.items.map((favorite) => (
      <FavoriteList
        key={favorite.id}
        id={favorite.id}
        title={favorite.title}
        description={favorite.description}
      />
    ));
  }

  return <div className={styles.favorite}>{favoriteOutput}</div>;
};

export default ProductsFavorite;
