/* main imports */
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

/* styles imoprt */
import "./index.css";

/* component imports */
import App from "./App";
import { ProductProvider } from "./store/products-context";
import { FavoriteProvider } from "./store/favorite-context";
import { AuthContextProvider } from "./store/auth-context";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ProductProvider>
        <FavoriteProvider>
          <App />
        </FavoriteProvider>
      </ProductProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
