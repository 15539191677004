/* main imports */
import { useContext } from 'react';

/* component imports */
import Card from '../UI/Card';
import FavoriteContext from '../../store/favorite-context';
import ProductsContext from '../../store/products-context';


/* styls import */
import styles from './FavoriteList.module.css'

const FavoriteList = (props) => {
    const favoriteCtx = useContext(FavoriteContext)
    const productCtx = useContext(ProductsContext)

    const removeItemHandler = () => {
        favoriteCtx.removeItemFromFavorite(props.id)
        productCtx.favoriteToggle(props.id)
    }

    return (
        <Card className={styles['favorite-item']}>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
            <div className={styles.actions}>
                <button onClick={removeItemHandler}>Remove Item</button>
            </div>
        </Card>
    )

}

export default FavoriteList