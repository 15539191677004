/* main import */
import { Fragment, useContext, useDebugValue } from "react";
import { NavLink, useNavigate } from "react-router-dom";

/* component imports */
import AuthContext from "../../store/auth-context";

/* styles import */
import styles from "./MainHeader.module.css";
import logo from "../../assets/logo.png";

const MainHeader = () => {
  const authCtx = useContext(AuthContext);
  const login = authCtx.isLoggedIn;
  const navigate = useNavigate()

  const logoutclickHandler = () => {
    authCtx.logout()
    navigate('/auth?method=login')
  }

  return (
    <header className={styles.header}>
      <NavLink to="/">
        <img src={logo} alt="logo" />
      </NavLink>
      <nav>
        {login && (
          <Fragment>
            <NavLink
              to="/"
              className={(navData) => (navData.isActive ? styles.active : "")}
            >
              Favorite List
            </NavLink>
            <NavLink
              to="/my-favorite"
              className={(navData) => (navData.isActive ? styles.active : "")}
            >
              My Favorite
            </NavLink>
            <button onClick={logoutclickHandler}>Logout</button>
          </Fragment>
        )}
        {!login && (
          <NavLink
            to="/auth"
            className={(navData) => (navData.isActive ? styles.active : "")}
          >
            Login / SignUp
          </NavLink>
        )}
      </nav>
    </header>
  );
};

export default MainHeader;
