/* main import  */
import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

/* components import */
import Layout from "./components/layout/Layout";
import NotFound from "./pages/NotFound";
import Product from "./pages/Product";
import MyFavorite from "./pages/MyFavorite";
import AuthContext from "./store/auth-context";
import Auth from "./pages/Auth";

function App() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const login = authCtx.isLoggedIn;

  useEffect(() => {
    if (!login) {
      navigate("/auth?method=login");
    }
  }, [login, navigate]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/my-favorite" element={<MyFavorite />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
