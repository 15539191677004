/* main imports */
import CSSTransition from "react-transition-group/CSSTransition";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useContext } from "react";

/* component imports */
import AuthContext from "../../store/auth-context";

/* styles import */
import styles from "./AuthForm.module.css";

const lowerLetter = /[a-z]/g;
const capitalLetter = /[A-Z]/g;
const number = /[0-9]/g;
const char = /[~,`,!,@,#,$,%,^,&,*,(,),_,-,+,=,{,[,},|,:,;,",',<,>,.,?,/,]/g;
let passwodrChart;

const passwordStroglityCheck = (password) => {
  let result = "";
  if (password.match(lowerLetter)) {
    result = "weak";
  }
  if (password.match(lowerLetter) && password.match(capitalLetter)) {
    result = "medium";
  }
  if (
    password.match(lowerLetter) &&
    password.match(capitalLetter) &&
    password.match(number)
  ) {
    result = "good";
  }
  if (
    password.match(lowerLetter) &&
    password.match(capitalLetter) &&
    password.match(number) &&
    password.match(char)
  ) {
    result = "strong";
  }
  return result;
};

const Authform = () => {
  const [isLoagin, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const authCtx = useContext(AuthContext)

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search).get("method");
  /* ---------------------- Validation ------------------------------------- */
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordInputIsTouched, setPasswordInputIsTouched] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [emailInputisTouched, setEmailInputIsTouched] = useState(false);

  const enteredPasswordIsValid =
    enteredPassword.length > 6 &&
    enteredPassword.match(lowerLetter) &&
    enteredPassword.match(capitalLetter);

  const passwordInputIsInvalid =
    !enteredPasswordIsValid && passwordInputIsTouched;

  const enteredEmailIsValid =
    enteredEmail.length > 7 && enteredEmail.includes("@");
  const emailInputIsInvalid = !enteredEmailIsValid && emailInputisTouched;

  let formIsValid = false;

  if (enteredPasswordIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }

  /* ---------------------- Change method ---------------------------------- */
  let login = true;
  if (searchParams === "signup") {
    login = false;
  }
  const switchClickHandler = () => {
    navigate(`/auth?method=${login ? "signup" : "login"}`);
  };
  /* ----------------------------------------------------------------------- */

  /* ----------------------------- Functions ------------------------------ */

  const passwordInputChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
    passwodrChart = passwordStroglityCheck(event.target.value);
  };
  const passwordInputBlurHandler = () => {
    setPasswordInputIsTouched(true);
  };

  const emailInputChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };
  const emailInputBlurHandler = () => {
    setEmailInputIsTouched(true);
  };

  const submissionHandler = (event) => {
    event.preventDefault();

    setPasswordInputIsTouched(true);
    setEmailInputIsTouched(true);

    if (!enteredPasswordIsValid && !enteredEmailIsValid) {
      return;
    }

    let url;
    setIsLoading(true);
    if (login) {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCjHAQjEYTuVXiz8cTxqXz5lz3B5YurFIw";
    } else {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCjHAQjEYTuVXiz8cTxqXz5lz3B5YurFIw";
    }

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: enteredEmail,
        password: enteredPassword,
        returnSecureToken: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication failed!";
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // }
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        const expiresTime = new Date(
          new Date().getTime() + +data.expiresIn * 1000
        );
        authCtx.login(data.idToken, expiresTime);
        navigate("/");
      })
      .catch((err) => {
        alert(err.message);
      });

    console.log(enteredEmail);
    console.log(enteredPassword);

    setEnteredPassword("");
    setPasswordInputIsTouched(false);

    setEnteredEmail("");
    setEmailInputIsTouched(false);
  };
  return (
    <form className={styles.form}>
      <h2>{login ? "Login" : "Sign Up"}</h2>
      <div
        className={`${styles.control} ${
          emailInputIsInvalid ? styles.invalid : ""
        }`}
      >
        <label htmlFor="email">Email :</label>
        <input
          type="text"
          name="email"
          value={enteredEmail}
          onChange={emailInputChangeHandler}
          onBlur={emailInputBlurHandler}
        />
      </div>
      <div
        className={`${styles.control} ${
          passwordInputIsInvalid ? styles.invalid : ""
        }`}
      >
        <label htmlFor="passw">Password :</label>
        <input
          type="password"
          name="passw"
          value={enteredPassword}
          onChange={passwordInputChangeHandler}
          onBlur={passwordInputBlurHandler}
        />
        <CSSTransition
          in={passwordInputIsInvalid}
          timeout={550}
          classNames={{
            enter: "",
            enterActive: styles["error-open"],
            exit: "",
            exitActive: styles["error-close"],
            appear: styles["error-apear"],
          }}
          mountOnEnter
          unmountOnExit
        >
          <p className={styles.error}>
            Password most be more than 6 and contain of lowerCase and capital
          </p>
        </CSSTransition>
      </div>
      <CSSTransition
        in={enteredPassword.length > 2 && !login}
        timeout={700}
        classNames={{
          enter: "",
          enterActive: styles["stronglity-open"],
          exit: "",
          exitActive: styles["stronglity-exit"],
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={`${styles.stronglity} ${styles[passwodrChart]}`}>
          <p>{passwodrChart}</p>
          <div className={styles.chart}>
            <div></div>
          </div>
        </div>
      </CSSTransition>
      <div
        className={`${styles.actions} ${!formIsValid && styles.invalid}`}
        onClick={submissionHandler}
      >
        <button>{login ? "Login" : "Sign Up"}</button>
      </div>
      <button
        type="button"
        className={styles.switch}
        onClick={switchClickHandler}
      >
        {login ? "Create Acount" : "You Have Acount"}
      </button>
    </form>
  );
};

export default Authform;
