/* styles import */
import styles from './ProductsItem.module.css'

const ProductsItem = props => {
    return <div className={styles.item}>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
    </div>
}

export default ProductsItem;